import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR";
import type { FC } from "react";
import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import GlobalStyles from "./components/GlobalStyles";
import RTL from "./components/RTL";
import CookiesWarning from "./components/shared/CookiesWarning";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import "./i18n";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createTheme } from "./theme";
import { clearDepositIntervalId } from "./utils/interval";

const locationDate = {
  br: pt,
  en: en,
  es: es,
};

const App: FC = () => {
  const content = useRoutes(routes);
  const location = useLocation();
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    const defaultSymbol = localStorage.getItem("defaultSymbol");
    if (!defaultSymbol || defaultSymbol === "undefined") {
      localStorage.setItem("defaultSymbol", "IDXUSDT");
      window.location.reload();
    }
    gtm.initialize(gtmConfig);
  }, []);

  useEffect(() => {
    clearDepositIntervalId();
  }, [location]);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  document.addEventListener(
    "touchmove",
    function (event) {
      // @ts-ignore
      if (event.scale !== 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locationDate[settings.language]}
    >
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <ToastContainer limit={3} />
          <CookiesWarning />
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
